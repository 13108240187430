@import "../../assets/scss/variables";

.testimonials-container {
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.slick-slider.slick-initialized {
  position: static;

  & > .slick-list {
    margin: 0 -15px;

    .slick-track {
      display: flex;

      .slick-slide > div {
        padding: 0 15px;
      }
      .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > .slick-arrow.slick-prev {
    left: 25px;
    width: 30px;
    height: 30px;
    &::before {
      color: $primary;
      font-size: 30px;
    }
  }
  & > .slick-arrow.slick-next {
    right: 25px;
    width: 30px;
    height: 30px;
    &::before {
      color: $primary;
      font-size: 30px;
    }
  }
  & > .slick-dots {
    bottom: unset;
    left: 0;
    right: 0;
  }
}
