@import "bootstrap/scss/functions";

$primary: #e0c561;
$secondary: #f2f3f4;
$text: #2d2c32;

$font-family-base: "Mulish", sans-serif;

@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
