@font-face {
  font-family: Mulish;
  src: local("Mulish"), url("./assets/fonts/Mulish-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Mulish;
  src: local("Mulish"), url("./assets/fonts/Mulish-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Kelvinch;
  src: local("Kelvinch"),
    url("./assets/fonts/Kelvinch-Roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Kelvinch;
  src: local("Kelvinch"),
    url("./assets/fonts/Kelvinch-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.main-container {
  max-width: 1600px;
}

h1,.kelvinch {
  font-family: 'Kelvinch', 'Times New Roman', Times, serif;
}

h2,h3,p,.mulish {
  font-family: 'Mulish', 'Verdana', 'Geneva', 'Tahoma', sans-serif;
}