.btn-primary {
  @include button-variant(
    $primary,
    $primary,
    #fff,
    lighten($primary, 5%),
    lighten($primary, 10%),
    #fff
  );
  border-radius: 0;
}

.form-control {
  border-radius: 0;
}

body {
  color: $text
}

.primary {
  color: $primary;
}