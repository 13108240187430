@import "../../assets/scss/variables";

.color-primary {
  color: $primary;
}

.grid-container > .col {
  min-height: 150px;
  @include media-breakpoint-up(lg) {
    min-height: 200px;
  }
}
